.login {
  &__container {
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 400px;
    padding-bottom: 30px;
    top: -35px;
    left: 120px;

    @include respond(phone) {
      max-width: 400px;
      width: 100%;
      left: 0;
    }
  }

  &__logo {
    width: 200px;
    margin: 0 auto 42px;
  }

  &__title {
    @include subheading;
    margin: 0 auto 21px;
    text-align: center;
    color: $white;
    @include respond(phone) {
      width: calc(100% - 40px);
    }
  }

  &__widget {
    @include white-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    margin-bottom: 20px;
    @include respond(phone) {
      margin: 0 20px 20px;
      width: calc(100% - 20px);
      padding: 30px 30px;
    }

    .visibility__icon {
      @include visibility-icon;
    }
  }

  &__error {
    font-size: 12px;
    font-weight: bolder;
    color: $error;
  }

  &__btn {
    @include blue-btn;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--disabled {
      margin-top: 10px;
      &:hover {
        color: $white;
      }
      background: $disabled;
    }

    &--privacy {
      @include blue-btn;
      width: 160px;
      margin: 40px auto 0;
    }
  }

  &__error {
    color: $error;
    @include paragraph;
    text-align: center;
  }

  &__comment,
  &__link {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;

    &--cookies {
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        color: $blue;
      }
    }
  }

  &__link {
    @include disabled-btn;
    width: 100%;

    &--red:hover {
      background-color: $error;
    }
  }

  &__label {
    margin-top: 16px;
  }

  &__success-msg {
    @include paragraph;
    color: $white;
    text-align: center;
    font-weight: 600;
  }

  &__input-container {
    position: relative;
  }

  &__video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }
}
