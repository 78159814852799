// MEDIA QUERY MANAGER

/* 
0 - 600px:       Phone
600 - 900px:     Tablet portrait
900 - 1200px:    Tablet landscape
[1200 - 1800]:   Normal styles apply  
1800px +:        Big desktop
*/

/* 
$breakpoint arguement choices: 
- phone
- tab-port
- tab-land
- big-desktop
*/

// Media querys are not affected by html font-size
// rems don't work well
// 1em = 16px
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 47.6em) {
      //767px
      @content;
    }
  }
  @if $breakpoint == tablet {
    //1060px
    @media only screen and (max-width: 66.25em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    //1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

// BUTTONS
@mixin blue-btn {
  background: $blue;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: $blue-active;
    color: $white;
  }

  @include btn-clickable;
}

@mixin btn-clickable {
  transition: all 0.2s;
  &:active {
    transform: scale(0.95);
  }
}

@mixin disabled-btn {
  background: $disabled;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: $disabled-active;
    color: $white;
  }

  @include btn-clickable;
}

@mixin tab {
  all: unset;
  border-radius: 4px;
  text-decoration: none;
  background: $blue;
  font-size: 16px;
  font-weight: 400;
  color: $white;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;

  @media screen and (max-width: 429px) {
    width: 100%;
  }
}

@mixin gray-heading {
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: $white;
}

@mixin widget-description {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: unset;
  color: $dark-gray;
  min-height: 21px;
  margin: 0;
}

// EFFECTS

@mixin scale-transition {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }
}

@mixin scale-transition-medium {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.02);
  }
}

@mixin shadow-on-hover {
  transition: 0.2s;
  &:hover {
    box-shadow: $shadow;
  }
}

@mixin input {
  height: 38px;
  @include paragraph;
  font-weight: 400;
}

@mixin background-gradient {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd, 80px);
  background-size: 600px;
}

// TYPOGRAPHY

@mixin main-heading {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;

  @include respond(big-desktop) {
    font-size: 30px;
  }
}

@mixin subheading {
  font-size: 24px;
  font-weight: 600;

  @include respond(big-desktop) {
    font-size: 26px;
  }
}

@mixin subheading-small {
  font-size: 18px;
  font-weight: 600;
}

@mixin paragraph {
  font-size: 16px;
}

@mixin event-title {
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $dark-gray;

  @include respond(big-desktop) {
    font-size: 26px;
    height: 72px;
    max-height: 72px;
    min-height: 72px;
  }
}

@mixin category-title {
  @include subheading-small;
  padding-left: 6px;
  border-left: 6px solid $orange;
  background: $placeholder;
  line-height: 2;
  border-radius: 0 4px 4px 0;
  margin-bottom: 15px;
}

@mixin white-box {
  background: $white;
  border-radius: 4px;
}

@mixin visibility-icon {
  position: absolute;
  top: 13px;
  right: 15px;
  font-size: 15px;
  color: $disabled-active;
  cursor: pointer;
}
