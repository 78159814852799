.account {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__warning {
    border-top: 1px solid $orange;
    border-bottom: 1px solid $orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    margin-bottom: 20px;
  }

  &__info-container {
    display: flex;
    align-items: center;

    &--start {
      display: flex;
      align-items: center;
    }
  }

  &__icon,
  &__info,
  &__important,
  &__required {
    @include paragraph;
  }

  &__important {
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__important,
  &__info {
    text-align: center;
  }

  &__icon {
    color: $orange;
    margin-right: 6px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    label {
      @include paragraph;
      white-space: nowrap;
    }

    .visibility__icon {
      @include visibility-icon;
      top: 36px;
      right: 11px;
    }

    .profile__img-container {
      width: 200px;
      height: 200px;
      margin: 0;
      border-radius: 4px;
      @include scale-transition-medium;

      img {
        width: 200px;
      }
    }

    .user__input--photo {
      flex-direction: column;
      gap: 12px;
      min-width: 200px;
      max-width: 200px;

      @media screen and (max-width: 1200px) {
        max-width: unset;
      }

      &-action {
        justify-content: flex-start;

        label.profile__btn {
          margin: 0;
          width: 200px;
        }

        .input__error--photo {
          top: 69px;
        }
      }

      .help__container .help__text {
        flex-wrap: wrap;
        text-align: center;
        @media screen and (max-width: 1200px) {
          flex-wrap: nowrap;
        }
      }
    }

    .profile__files {
      @include respond(phone) {
        margin: 20px auto 0;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    column-gap: 30px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    &:first-of-type {
      column-gap: 100px;
      margin-bottom: 30px;
    }

    &--last {
      @include respond(tablet) {
        flex-direction: column-reverse;
      }
    }

    &--centered {
      justify-content: center;
      column-gap: 30px;
    }
  }

  &__names {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__firstnames {
    display: flex;
    gap: 30px;
  }

  &__salutation {
    width: 25%;
    @media screen and (max-width: 1200px) {
      height: 90px;
    }
  }

  &__name {
    width: 75%;

    @include respond(tablet) {
      height: 67px;
    }
  }

  &__password.user__input {
    position: relative;
    margin-bottom: 0;
    flex: 1;
  }

  &__phones {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__phone {
    display: flex;
    width: 100%;
  }

  &__reason {
    width: 39%;
  }

  &__surname,
  &__email {
    width: 100%;
    margin-top: 10px;
  }

  &__password-info {
    display: flex;
    width: 80%;
    margin: 10px 0 20px;

    @include respond(tablet) {
      margin: 0;
    }
  }

  &__code {
    width: 33%;
    margin-right: 10px;
  }

  &__number {
    width: 67%;
  }

  &__country,
  &__city,
  &__firm {
    flex: 1;
  }

  &__country,
  &__city {
    @include respond(tablet) {
      margin-bottom: 20px;
    }
  }
  &__location-container {
    justify-content: space-between;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__password,
  &__email,
  &__reason,
  &__surname,
  &__password-info,
  &__number,
  &__country,
  &__city,
  &__firm,
  &__phones {
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  &__email {
    @media screen and (max-width: 1200px) {
      margin-top: 20px;
    }
  }

  &__name,
  &__password,
  &__phones,
  &__country,
  &__city,
  &__firm,
  &__reason {
    @media screen and (max-width: 1200px) {
      margin-bottom: 23px;
    }
  }

  &__actions {
    display: flex;
    gap: 30px;
    margin-left: auto;
    @include respond(phone) {
      width: 100%;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  }

  &__cancel {
    width: 130px;
    min-width: 130px;
    @include disabled-btn;
    @include respond(tablet) {
      width: 100%;
    }

    &:hover {
      background-color: $error;
    }
  }
}
